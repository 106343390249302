import React from "react"
import Header from "../../components/services/servicesHeader"
import ServicesBottomNav from "../../components/services/servicesBottomNav"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import Background from "../../../assets/images/servicesHeader/mobile_icon.svg"
import arrow from "../../../assets/images/servicesPages/arrowDown.png"
import mobile from "../../../assets/images/servicesPages/mobile.png"

export default () => (
    <div>
        <SEO title={'ICON Worldwide | Mobile Development Services'} 
        description="ICON's expert developers have been designing and building award-winning custom mobile apps since 2007: learn more about our mobile development services!"
        canonical={'https://icon-worldwide.com/services/mobile-development'}/>
        <Header headerTitle="Mobile Development" headerSubTitle="High-performance mobile applications."  headerBackground={Background}/>
        <div id="servicesPagesInner">
            <div className="winningTeam">
                <div className="text-info">
                    <p>Since 2007 ICON Worldwide has been designing and building award winning custom mobile apps.
                    Our software engineering centre in Sofia, Bulgaria has built more then 500 consumer facing
                    to highly specialised mobile apps for startup ventures to world leading enterprises. 
                    We know the importance of design and technology and believe that interdisciplinary collaboration
                    is the key to innovative products. Our team is comprised of digital product strategists, user experience
                    designers and top-tier android and IOS software engineers.
                    </p>
                    <p><a href="https://www.designrush.com/agency/app-design-companies" target="_blank" rel="noopener noreferrer">Top App Design Companies Of 2020 according to DesignRush</a></p>
                    <p><a href="https://www.designrush.com/agency/magento/trends/mobile-app-for-magento" target="_blank" rel="noopener noreferrer">Mobile App For Magento</a></p>
                </div>
            </div>
            <img className="arrowDown" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
            <div className="normal-image">
                <img src={mobile} alt="mobile application development, ICON Worldwide, FlavorWiki" title=" ICON Worldwide, mobile application development, FlavorWiki "/>
            </div>
            <div className="twoColumns">
                <div className="block-two">
                    <h2>Native iOS Development</h2>
                    <p>We understand how to leverage the complete Apple experience,
                        from iPhone, iPad, Apple Watch and Apple TV. Our top-tier
                        iOS software engineers have deployed over 500 consumer facing
                        to highly specialised mobile apps.</p>
                    <div className="words">
                        <h3>User Interface Design specifically for iPhone and iPad devices</h3><i>/</i><h3>Deep Integration with native IOS functions such as Notifications, Contacts, and Calendar</h3><i>/</i>
                        <h3>Leveraging App Store distribution</h3><i>/</i><h3>Built in Swift or Object C</h3>
                    </div>
                </div>

                <div className="block-two">
                    <h2>Native Android Development</h2>
                    <p>We have built highly specialized Android applications such as blockchain wallets
                         and medical devices. We optimize Android development for a wide range of phone
                        and tablet specifications.</p>
                    <div className="words">
                        <h3>User Interface Design specifically for Android devices</h3><i>/</i><h3>Native Java or converted from IOS</h3><i>/</i><h3>Xamarin Cross-Platform Framework</h3><i>/</i>
                        <h3>Flexible for a constantly changing range of devices and providers </h3><i>/</i><h3>Leveraging the Google Play store</h3>
                    </div>
                </div>
            </div>
            <div className="yellowLine"></div>
            <ServicesBottomNav/>
            </div>
        <Footer noScrollbar="true"/>
    </div>
)